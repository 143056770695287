<template>
  <div class="bfc-market">
    <div class="hero">
      <h1>Sogelac</h1>
    </div>

    <div class="strip">
      <p>November 2021 <span>✹ </span> December 2021</p>
      <p class="index">002</p>
    </div>

    <div class="info">
      <div class="context">
        <h2>The context</h2>
        <p>
          In november 2021, I was in charge of redoing the website of SOGELAC. They wanted a custom Wordpress website with the possibility to add their own content to the site. It naturally had to be responsive and optimized for SEO.
        </p>
        <p>
          SOGELAC is a Wealth Management Company and Multi Family Office created in 2006 and entirely owned by its managers ensuring a total independence.
        </p>
      </div>
      <div class="right-info">
        <div class="contribution">
          <h2>My contribution</h2>
          <p>
            My contributions to this project is mostly the website development.
            I was in charge of the wordpress theme development made from scratch with Laravel and I also worked on the website animations, loader and page transitions.
          </p>
        </div>

        <div class="result">
          <h2>The result</h2>
          <p>
            The website is clean and elegant, the performances and the SEO are optimized and the client is happy with the result. This project allowed me to understand how page transitions and loader worked in AJAX.
          </p>
          <a
              href="https://www.sogelac.ch/"
              target="_blank"
              rel="noopener noreferrer"
          >Visit the project right here.</a
          >
        </div>
      </div>
    </div>

    <div class="mockup-holder section2">
      <div class="mockup">
        <img
          src="../../assets/images/projects/sogelac.png"
          alt="the bfc market design"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bfc-market {
  margin: 5rem 2rem;
  @media screen and (max-width: 800px) {
    margin: 0 1rem;
  }
}

h1 {
  font-size: 15vw;
  line-height: 100%;
  margin: 0;
  @media screen and (min-width: 1700px) {
    font-size: 25rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 8rem;
  }
}

a {
  color: $blue;
  &:hover {
    text-decoration: underline;
  }
}

h2 {
  margin: 0;
  font-family: $font-body;
  color: $blue;
  font-size: 4rem;
  font-variation-settings: "wght" 400;
  font-weight: 400;
  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
}

.hero {
  height: 40vh;
  display: flex;
  align-items: flex-end;
}

.info {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  p {
    padding: 1rem 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}
.context,
.right-info {
  width: 40%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.strip {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  p {
    padding: 0.5rem 0;
    font-size: 5rem;
    font-variation-settings: "wght" 300;
    @media screen and (max-width: 800px) {
      font-size: 2rem;
    }
  }
  .index {
    text-align: end;
  }
  span {
    color: $green;
  }
}

.mockup-holder {
  margin: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mockup {
  width: 50%;
  max-width: 100rem;
  img {
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "Sogelac",
};
</script>
